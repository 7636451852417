import React, { useEffect } from 'react'
import Video from '../../Media/almacenVideo.mp4'

export default function HeroSectionLanding({handleClick}) {

  return (

    <div className="bg-white">
      
        {/* Hero section */}
        <div className="isolate relative pt-14">
          <div
            className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
            aria-hidden="true"
          >
            <div
              className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[50rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-sky-300 to-red-300 opacity-40 sm:left-[calc(50%-30rem)] sm:w-[100rem]"
              style={{
                clipPath:
                  'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
              }}
            />
          </div>
          <div className="pt-24 sm:pt-32 lg:pt-56">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto max-w-2xl text-center">
                <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                  Solución a tiempo real
                </h1>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  En VisionTec, fusionamos la innovación en equipos de código de barras y tecnología RFID con soluciones de software a medida, impulsando su empresa a la vanguardia tecnológica. 
                </p>
                {/* <div className="mt-10 flex items-center justify-center gap-x-6">
                  <a
                    href="#"
                    className="rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Comienza ya
                  </a>
                  <p onClick={handleClick}  className="text-sm font-semibold leading-6 text-gray-900">
                    Conoce más <span aria-hidden="true">→</span>
                  </p>
                </div> */}
              </div>

              {/* Video */}
              <div className="mt-24 flow-root sm:mt-32">
                <div className="-m-2 rounded-xl bg-gray-900/5 lg:rounded-2xl">
                  <video 
                    width={2432}
                    height={1442} 
                    className='rounded-xl lg:rounded-2xl shadow-xl shadow-gray-400' 
                    muted={true} autoPlay loop>
                    <source src={Video} type="video/mp4"/>
                  </video>
                </div>
              </div>


            </div>
          </div>
          <div
            className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-50rem)]"
            aria-hidden="true"
          >
            <div
              className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-red-300 to-sky-300 opacity-50 sm:left-[calc(50%+36rem)] sm:w-[100rem]"
              style={{
                clipPath:
                  'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
              }}
            />
          </div>
        </div>
    </div>


    // <div>
    // <div className='h-screen'>
    //     <div className='absolute z-10 w-full
    //     md:left-[15%]'>
    //         <div className='max-w-md mt-52 leading-[1.1] text-[55px] mx-auto px-8 font-bold text-white tracking-normal
    //         md:max-w-xl md:leading-[0.95] md:text-7xl md:mx-0 md:px-0 md:tracking-wide '>
    //             Solución a tiempo real
    //             <div className='w-full grid grid-cols-2 mt-14 text-base gap-x-3 h-12 w-full
    //             md:w-5/6 md:gap-x-12
    //             md:text-lg'>
    //             <button className='transition duration-150 ease-in-out border-2 rounded-[22px] text-white border-white font-normal tracking-[0.05em] hover:bg-white hover:text-black font-semibold
    //             md:tracking-[0.075em]'
    //             onClick={()=>{
    //               window.scrollTo({top: 880, left: 0, behavior: 'smooth'});
    //             }}>Conoce más</button>
    //             <button className='transition duration-150 ease-in-out rounded-[22px] bg-sky-400 text-white  tracking-[0.05em] font-normal hover:bg-sky-300 font-semibold 
    //             md:tracking-[0.075em]'>eCommerce</button>
    //             </div>
    //         </div>
    //     </div>
    //     <video width='100%' className='h-screen object-cover brightness-50 z-0' muted={true} autoPlay loop>
    //     <source src={Video} type="video/mp4"/>
    //     </video>
    // </div>
    // </div>
  )
}
