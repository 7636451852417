import React, { useEffect, useRef, useState } from 'react'
import Foto_1 from '../../Media/audit.jpg'
import Foto_2 from '../../Media/servicios2.jpg'
import Foto_3 from '../../Media/servicios3.jpg'
import Icon_1 from '../../Media/automatIcon.png'
import Icon_2 from '../../Media/auditIcon.png'
import Icon_3 from '../../Media/credencialIcon.png'
import Icon_4 from '../../Media/digitalIcon.png'
import Icon_5 from '../../Media/softwareIcon.png'
import Icon_6 from '../../Media/controlIcon.png'
import Icon_7 from '../../Media/polizaIcon.png'
import HeroSection from '../Elements/HeroSection'
import ServiceTab from '../Elements/ServiceTab'
import ServiceItem from '../Elements/ServiceItem'
import ServiceInfoSection from '../Elements/ServiceInfoSection'
import { data } from '../../Data/infoServicesPage'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import TiktokPreview from '../Elements/TiktokPreview';
import Video from '../../Media/almacenVideo.mp4'
import { useLocation } from 'react-router-dom'
import TiktokCarousel from '../Elements/TiktokCarousel'

const videos = [
    Video,
    Video,
    Video,
    Video,
    Video,
    Video
  ]

export default function ServicesPage() {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    const location = useLocation()
    const loadRef = useRef(null);
    const initialState = {
        control: false,
        audit: false,
        credencial: false,
        poliza: false,
        software: false
    };
    
    const [clicked, setClicked] = useState(initialState);

    const [serviceData, setServiceData] = useState({
        info: {
            name: '',
            subtitle: '',
            description: '',
            img: ''
        },
        bullet: []
    }
    )


    useEffect(()=>{

        if(location.state === undefined || location.state === null){
            setClicked(({...initialState, control: true}))
            setServiceData(data.control)
            window.scrollTo(0, 0)
        } 
        else if (location.state == 'control'){
            setClicked(({...initialState, control: true}))
            setServiceData(data.control)
            loadRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
        else if (location.state == 'audit'){
            setClicked(({...initialState, audit: true}))
            setServiceData(data.audit)
            loadRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
        else if (location.state == 'cred'){
            setClicked(({...initialState, credencial: true}))
            setServiceData(data.credencial)
            loadRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
        else if (location.state == 'poliza'){
            setClicked(({...initialState, poliza: true}))
            setServiceData(data.poliza)
            loadRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
        else{
            setClicked(({...initialState, software: true}))
            setServiceData(data.software)
            loadRef.current?.scrollIntoView({ behavior: 'smooth' });
        }

        window.history.replaceState({}, document.title)
    }, [location.state])

    useEffect(()=>{
        if(clicked.control == true){
            setServiceData(data.control)
      
          } else if(clicked.audit == true){
            setServiceData(data.audit)
      
          } else if(clicked.credencial == true){
            setServiceData(data.credencial)
      
          } else if(clicked.poliza == true){
            setServiceData(data.poliza)
      
          } else {
            setServiceData(data.software)
      
          }
    }, [clicked])




  return (



    <div className=''>

        <HeroSection title={'Servicios'} subtitle={["Llevamos tu", <div>Visión a Realidad</div>]} text={'Descubre como realizamos tus oportunidades a resultados, fusionando tecnología de radiofrecuencia con software personalizado.'} img1={Foto_1} img2={Foto_2} img3={Foto_3}/>


        {/* PESTAÑAS SERVICIOS */}
        <div className='w-full h-auto
        relative'>
            {/* <div className='w-full mt-16 mb-2
            md:w-2/3 md:ml-auto md:mr-auto md:mb-0'> */}
            <div className='mt-16 mb-2
            sm:max-w-[47rem] sm:ml-auto sm:mr-auto sm:px-5 sm:mb-0
            lg:max-w-[60rem]
            2xl:max-w-7xl'>
                <div className='mb-14 tracking-wide italic text-xl text-zinc-700 w-3/4 ml-auto mr-auto
                 sm:w-full sm:ml-0 sm:mr-0 sm:text-2xl'>
                    Contamos con los siguientes servicios:
                </div>
                <div onClick={()=> {
                    loadRef.current?.scrollIntoView({ behavior: 'smooth' });
                }} 
                className='h-auto flex flex-col gap-y-2
                sm:gap-y-[0px] sm:gap-x-2 sm:grid sm:grid-cols-5 sm:inline'>

                    <ServiceTab id={'control'} icon={Icon_6} name={["Optimización de ", <div>Activos Fijos</div>]} 
                    initialState={initialState} setClicked={setClicked} clickedTab={clicked.control}/>

                    <ServiceTab id={'audit'} icon={Icon_2} name={'Auditoría de inventarios'} 
                    initialState={initialState} setClicked={setClicked} clickedTab={clicked.audit}/>

                    <ServiceTab id={'credencial'} icon={Icon_3} name={'Credencialización'} 
                    initialState={initialState} setClicked={setClicked} clickedTab={clicked.credencial}/>

                    <ServiceTab id={'poliza'} icon={Icon_7} name={['Garantías', <div>de Servicio</div>]} 
                    initialState={initialState} setClicked={setClicked} clickedTab={clicked.poliza}/>

                    <ServiceTab id={'software'} icon={Icon_5} name={['Desarrollo', <div>de Software</div>]} 
                    initialState={initialState} setClicked={setClicked} clickedTab={clicked.software}/>



                </div>
            </div>

            {/* <div className={`${clicked.control == true ? 'bg-red-300' : 
                clicked.audit == true ? 'bg-orange-300' :
                clicked.credencial == true ? 'bg-green-300' :
                clicked.software == true ? 'bg-blue-300' : 'bg-purple-300' } absolute w-full h-[532px] md:hidden bottom-0 right-0 -z-10`}></div> */}
        </div>


        {/* INFORMACIÓN */}
        <div ref={loadRef} className={`${clicked.control == true ? 'bg-red-300' : 
            clicked.audit == true ? 'bg-orange-300' :
            clicked.credencial == true ? 'bg-green-300' :
            clicked.poliza == true ? 'bg-blue-300' : 'bg-purple-300' } `}>

            <div className='w-full grid grid-cols-1 h-[37rem] pt-20 pb-16
            relative sm:h-auto sm:py-32 sm:grid sm:grid-cols-10 
            lg:grid lg:grid-cols-2'>
                <div className='content-center mx-auto px-10
                sm:px-0 sm:mx-0 sm:col-span-7 sm:max-w-lg z-10 sm:justify-self-center sm:self-center
                md:col-span-6
                lg:col-span-1
                xl:max-w-2xl z-10 xl:justify-self-end'>
                    <div className='h-full 
                    sm:col-start-3 sm:mx-16 
                    xl:mx-24'>
                        <div className='text-base text-white tracking-wide mb-3
                        [text-shadow:_2px_2px_3px_rgb(0_0_0_/_50%)]'>
                            {serviceData.info.name}
                        </div>
                        <div className='text-3xl max-w-xs h-[7.5rem] leading-[2.5rem] tracking-wide font-bold text-white mb-20
                        sm:max-w-full sm:h-auto sm:text-4xl sm:leading-[3rem] sm:mb-6
                        xl:text-[54px] xl:leading-[4rem] xl:mb-10'>
                            {serviceData.info.subtitle}
                        </div>
                        <div className='absolute top-0 inset-0 max-w-lg mx-auto px-8 text-base text-zinc-700 leading-7 mt-72
                        sm:relative sm:max-w-full sm:text-base sm:leading-9 sm:tracking-wide sm:mx-0 sm:px-0 sm:mt-0
                        xl:text-xl xl:leading-10'>
                            {serviceData.info.description}
                        </div>
                    </div>
                </div>

                <div className="absolute col-span-2 w-full h-52 overflow-hidden mt-14
                sm:relative sm:col-span-3 sm:h-[700px] sm:mt-0
                md:col-span-4
                lg:col-span-1">
                    <img src={serviceData.info.img}
                    id='foto'
                    className={` 
                    ${serviceData.info.name == 'Auditoría de inventarios' ? 'object-right' : 'object-center'} w-full h-full object-cover brightness-50
                    sm:brightness-100`}/>
                </div>
            </div>



            {/* PUNTOS */}
            <div className='w-full grid grid-cols-12 h-auto pb-24'>
                <div className='col-span-11 grid grid-cols-1 bg-white rounded-r-[4rem]
                lg:col-span-10
                2xl:col-span-9'>
                    <div className='mr-8 ml-10 mt-12 mb-16 max-w-full
                    sm:mr-10 sm:ml-10 sm:mb-24 sm:mt-20 sm:max-w-[40rem] 
                    lg:justify-self-center lg:mr-20 lg:ml-14 lg:max-w-[45rem]
                    2xl:justify-self-end 2xl:mr-32 2xl:max-w-[50rem]'>

                        <div className='text-3xl tracking-[0.035em] mb-14 font-bold text-zinc-400
                        sm:text-3xl  sm:leading-[4.1rem]
                        lg:text-[44px]'>
                            Nosotros te ofrecemos:
                        </div>
                        <div className='grid grid-cols-2 gap-x-8 gap-y-10
                        sm:grid sm:grid-cols-3 sm:gap-y-16
                        lg:gap-x-14
                        2xl:gap-x-20'>
                            {serviceData.bullet.map((data) => (
                                <div>
                                <ServiceItem icon={<data.icon className="w-full h-full"/>} name={data.name} description={data.description}/>
                                </div>
                            ))}

                        </div>
                    </div>
                </div>
            </div>


            {/* TIKTOKS */}
            {/* <TiktokCarousel videos={videos}/> */}

                
        </div>
    </div>
  )
}
