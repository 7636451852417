import {
    BuildingOfficeIcon,
    ArchiveBoxIcon,
    CogIcon,
    BuildingStorefrontIcon,
    TruckIcon,
    CircleStackIcon, 
    ClipboardDocumentListIcon,
    IdentificationIcon,
    ShieldCheckIcon,
    ComputerDesktopIcon
  } from '@heroicons/react/24/outline'

export const dropdownData = {
    Industries: [
        { name: 'Almacenes', href: '/vertical/warehouses', icon: ArchiveBoxIcon },
        { name: 'Manufactura', href: '/vertical/manufacture', icon: CogIcon },
        { name: 'Retail', href: '/vertical/retail', icon: BuildingStorefrontIcon },
        { name: 'Transporte y Logística', href: '/vertical/transport', icon: TruckIcon },
        { name: 'Hospitalidad', href: '/vertical/hospitality', icon: BuildingOfficeIcon }
    ], 
    Services: [
        { name: 'Optimización de Activos Fijos', description: 'Seguimiento, registro y gestión de bienes', href: '/services', linkRef: 'control', icon: CircleStackIcon },
        { name: 'Auditoria de inventarios', description: 'Revisión y verificación sistemática de registros', href: '/services', linkRef: 'audit', icon: ClipboardDocumentListIcon },
        { name: 'Credencialización', description: "Tarjetas de identificación y seguridad", href: '/services', linkRef: 'cred', icon: IdentificationIcon },
        { name: 'Garantías de Servicio', description: 'Seguros para cualquier eventualidad', href: '/services', linkRef: 'poliza', icon: ShieldCheckIcon },
        { name: 'Desarrollo de Software', description: 'Diseño de aplicaciones a tu medida', href: '/services', linkRef: 'software', icon: ComputerDesktopIcon},
    ]

}