import React, { useEffect, useRef, useState } from 'react'
import HeroSectionLanding from '../Elements/HeroSectionLanding'
import Icon from '../../Media/ideaIcon.png'
import FotoProd from '../../Media/lector.png'
import FotoServ from '../../Media/servicios1.jpg'
import FotoSolu from '../../Media/soluciones4.jpg'
import FotoEcom from '../../Media/ecommerce3.jpg'
import Foto_1 from '../../Media/automat.jpg'
import Foto_5 from '../../Media/software.jpg'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ServiceTabLanding from '../Elements/ServiceTabLanding'
import ProductPanel from '../Elements/ProductPanel'
import BtnSectionNav from '../Elements/BtnSectionNav'
import { Link, useLocation } from 'react-router-dom'
import { elementData, industryData, solutionData } from '../../Data/infoLandingPage'
import { storyData } from '../../Data/InfoStories'

export default function LandingPage() {

  // useEffect(() => {
  //   window.scrollTo(0, 0)
  // }, [])

  const initialStateServ = {
    control: false,
    audit: false,
    credencial: false,
    poliza: false,
    software: false
  };

  const [clicked, setClicked] = useState(initialStateServ);
  
  useEffect(() => {
    setClicked(({...initialStateServ, control: true}))
  }, [])

  const location = useLocation()
  const loadRef = useRef(null);
  const learnMoreRef = useRef(null);

  const handleLearnMore = () => {
    learnMoreRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(()=>{
    if (location.state == 'success'){
        loadRef.current?.scrollIntoView({ });
    }
    window.history.replaceState({}, document.title)
  }, [location.state])

  const [serviceBG, setServiceBG] = useState("from-orange-100 to-red-300")
  const [serviceBGRes, setServiceBGRes] = useState("from-red-300");
  const [serviceImg, setServiceImg] = useState(Foto_1)
  const productData = (
    {image: FotoProd,
      name: "Escáner Portatil",
      SKUcode: "A1560PCBWUS01",
      price: "299.00"
    }
  )
 

  const [clickedSol , setClickedSol] = useState(solutionData[0])


  useEffect(()=>{
    if(clicked.control == true){
      setServiceBG("from-orange-100 to-red-300");
      setServiceBGRes("from-red-300");
      setServiceImg(Foto_1);

    } else if(clicked.audit == true){
      setServiceBG("from-orange-100 to-amber-300");
      setServiceBGRes("from-orange-300");
      setServiceImg('https://d1ih8jugeo2m5m.cloudfront.net/2022/11/como-rastrear-un-paquete-1200x685.jpg');

    } else if(clicked.credencial == true){
      setServiceBG("from-green-100 to-lime-300");
      setServiceBGRes("from-green-300");
      setServiceImg('https://www.specialistid.com/cdn/shop/articles/Screenshot_2021-04-16_111756.png?v=1618586484');

    } else if(clicked.poliza == true){
      setServiceBG("from-indigo-100 to-sky-300");
      setServiceBGRes("from-blue-300");
      setServiceImg('https://segurosatlantida.com/wp-content/uploads/2018/05/renovar.jpg');

    } else {
      setServiceBG("from-pink-100 to-purple-300");
      setServiceBGRes("from-purple-300");
      setServiceImg(Foto_5);

    }
    
  }, [clicked])


  function ChangeTab(tab) {
    

    switch(tab){
      case "control":
          if(clicked.control == false){
            setClicked(({...initialStateServ, control: true}))
            }
          break;
      case "audit":
          if(clicked.audit == false){
            setClicked(({...initialStateServ, audit: true}))
            }
          break;
      case "credencial":
          if(clicked.credencial == false){
            setClicked(({...initialStateServ, credencial: true}))
            }
          break;
      case "poliza":
          if(clicked.poliza == false){
            setClicked(({...initialStateServ, poliza: true}))
            }
          break;
      default:
          if(clicked.software == false){
            setClicked(({...initialStateServ, software: true}))
            }
          break;
    }
  }

  return (
    <div>
        <HeroSectionLanding handleClick={handleLearnMore} />

        {/* INFO */}

        <div ref={learnMoreRef}/>

        <div className="mx-auto mt-36 max-w-7xl px-6 sm:mt-44 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-base font-semibold leading-7 text-blue-600">La diferencia tecnológica</h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              ¿Cuál es nuestra filosofía?
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-600">
             Tenemos como misión optimizar procesos para la industria asegurando calidad en el servicio y tecnología de vanguardia.
            </p>
          </div>
          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
            <dl className="grid mx-auto max-w-xl grid-cols-2 gap-x-10 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-4">
              {elementData.map((feature) => (
                <div key={feature.title} className="flex flex-col">
                  <dt className="text-base font-semibold leading-7 text-gray-900">
                    <div className="mb-6 flex h-10 w-10 items-center justify-center">
                      <img
                        src={feature.URLimage}
                        alt='Foto'
                        placeholder='blur'
                        className='object-cover'
                        aria-hidden="true"
                      />
                    </div>
                    {feature.title}
                  </dt>
                  <dd className="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-600">
                    <p className="flex-auto">{feature.info}</p>
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>



        {/* Newsletter section */}
        {/* <div className="mx-auto mt-32 max-w-7xl sm:mt-56 sm:px-6 lg:px-8">
          <div className="relative isolate overflow-hidden bg-gray-900 px-6 py-24 shadow-2xl sm:rounded-3xl sm:px-24 xl:py-32">
            <h2 className="mx-auto max-w-2xl text-center text-3xl font-bold tracking-tight text-white sm:text-4xl">
              ¿Interesado en conocer más?
            </h2>
            <p className="mx-auto mt-2 max-w-xl text-center text-lg leading-8 text-gray-300">
              Descubre más acerca de nuestra empresa, servicios y productos.
            </p>
            <div className="mx-auto mt-10 flex justify-center max-w-md gap-x-4">
              <button
                type="submit"
                className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
              >
                Notifícame
              </button>
            </div>
            <svg
              viewBox="0 0 1024 1024"
              className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2"
              aria-hidden="true"
            >
              <circle cx={512} cy={512} r={512} fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fillOpacity="0.7" />
              <defs>
                <radialGradient
                  id="759c1415-0410-454c-8f7c-9a820de03641"
                  cx={0}
                  cy={0}
                  r={1}
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(512 512) rotate(90) scale(512)"
                >
                  <stop stopColor="#7775D6" />
                  <stop offset={1} stopColor="#E935C1" stopOpacity={0} />
                </radialGradient>
              </defs>
            </svg>
          </div>
        </div> */}


        {/* INDUSTRIAS */}
        {/* <div className='w-full h-screen bg-zinc-200'>
        </div> */}

        <div className="relative isolate mt-32 sm:mt-24 sm:pt-32">
          <div
            className="absolute inset-x-0 top-1/2 -z-10 -translate-y-1/3 transform-gpu overflow-hidden opacity-40 blur-3xl"
            aria-hidden="true"
          >
            <div
              className="ml-[max(50%,38rem)] aspect-[1313/771] w-[82.0625rem] bg-gradient-to-tr from-red-400 from-15% to-sky-400"
              style={{
                clipPath:
                  'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
              }}
            />
          </div>
          <div
            className="absolute inset-x-0 top-0 -z-10 flex transform-gpu overflow-hidden pt-8 opacity-50 blur-3xl md:justify-end"
            aria-hidden="true"
          >
            <div
              className="ml-[-22rem] aspect-[1313/771] w-[82.0625rem] flex-none origin-top-right rotate-[30deg] bg-gradient-to-tr from-sky-300 to-red-300 md:ml-0 md:mr-[calc(50%-12rem)]"
              style={{
                clipPath:
                  'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
              }}
            />
          </div>

          <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto max-w-2xl sm:text-center">
                <h2 className="text-lg font-semibold leading-8 tracking-tight text-blue-600">Industrias</h2>
                <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                  Una solución para cada vertical
                </p>
              </div>

            <div className="mx-auto grid-cols-1 mt-16 max-w-xl grid auto-rows-[150px] 
            sm:auto-rows-[200px] sm:grid-cols-2 
            md:max-w-2xl
            xl:grid-cols-3 gap-6 xl:mt-20 xl:max-w-5xl">
              {industryData.map((data, i) => (
                <div
                  key={i}
                  className={`relative group  row-span-1 rounded-xl border-2 border-slate-400/10 bg-neutral-100  dark:bg-neutral-900 overflow-hidden ${
                    i === 0  ? "sm:col-span-2 md:row-span-2" : i === 4 ? "xl:col-span-2" : ""
                  }`}
                >
                  <Link to={data.link}>
                  <img
                    src={data.img}
                    alt="App screenshot"
                    className="object-cover transition duration-150 ease-in-out brightness-[.85] contrast-100 blur-none group-hover:brightness-[.7] group-hover:contrast-75 group-hover:blur-[2px] group-hover:scale-[1.07] lg:object-fill h-full w-full"
                  />
                  <div className="absolute bottom-10 left-10 mx-auto max-w-2xl lg:mx-0">
                          <p className={`mt-6 text-2xl font-semibold text-white transition duration-300 ease-in-out group-hover:scale-110 group-hover:translate-x-5
                           sm:text-3xl sm:leading-8 ${
                            i === 0  ? " md:text-6xl" : i === 4 ? "xl:text-4xl" : ""
                          }`}>
                            {data.name}
                          </p>
                    </div>
                    </Link>
                </div>
              ))}
            </div>
          </div>
        </div>




        {/* SOLUCIONES */}
        
        <div className='relative isolate mt-40 sm:mt-52'>


        <div className='px-6 lg:px-8'>
        <div className="mx-auto max-w-4xl text-center">
              <h2 className="text-base font-semibold leading-7 text-blue-600">Soluciones</h2>
              <p className="mt-2 text-4xl leading-10 font-bold tracking-tight text-gray-900 sm:leading-[4rem] sm:text-5xl">
                Soluciones integrales para cubrir todas tus necesidades de Software y Hardware
              </p>
            </div>
        <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
          Familiarízate con los procesos de integración.
        </p>
        </div>


        <div className='relative mt-16 sm:mt-20'>
        <div className="absolute -z-40 bottom-[5%] w-full h-[90%] bg-gray-900">
          <div className="absolute inset-0 overflow-hidden" aria-hidden="true">
            <div className="absolute left-[calc(50%-19rem)] top-[calc(50%-36rem)] transform-gpu blur-3xl">
              <div
                className="aspect-[1097/1023] w-[68.5625rem] bg-gradient-to-r from-[#ff4694] to-[#776fff] opacity-25"
                style={{
                  clipPath:
                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                }}
              />
            </div>
          </div>
        </div>


        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="relative isolate overflow-hidden bg-gray-700 sm:rounded-3xl">


          <div>
            <div className="block">
              <nav className="isolate flex divide-x divide-gray-400 shadow" aria-label="Tabs">
                {solutionData.map((tab, i) => (
                  <p
                    key={tab.tab}
                    onClick={()=>{setClickedSol(solutionData[i])}}
                    className={`${tab.tab == clickedSol.tab ? 'text-white bg-gray-700 hover:text-white hover:bg-gray-700 hover:cursor-default' : 'bg-gray-300 text-gray-600 hover:text-gray-700'} 
                    hover:cursor-pointer group relative min-w-0 flex-1 overflow-hidden py-4 px-4 text-center text-sm font-medium hover:bg-gray-100 focus:z-10`
                    }
                    //tab.current ? '' : 'bg-gray-300 text-gray-600 hover:text-gray-700',
                    //tabIdx === 0 ? '' : '',
                    //tabIdx === tabs.length - 1 ? '' : '',
                    aria-current={tab.tab == clickedSol.tab ? 'page' : undefined}
                  >
                    <span>{tab.tab}</span>
                    <span
                      aria-hidden="true"
                      className={`${tab.tab == clickedSol.tab ? 'bg-blue-500' : 'bg-transparent'}
                        absolute inset-x-0 bottom-0 h-0.5`
                      }
                    />
                  </p>
                ))}
              </nav>
            </div>
          </div>

                      
          <div className='px-6 py-20 sm:px-10 sm:py-24 lg:py-24 xl:px-24'>
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-center lg:gap-y-0">
              <div className="lg:row-start-2 lg:max-w-md">
                <p className="text-lg leading-8 text-gray-300">
                  {clickedSol.title}
                </p>
                <h2 className="mt-3 text-3xl font-bold tracking-tight sm:leading-[3rem] text-white sm:text-4xl">
                  {clickedSol.subtitle}
                </h2>
                <p className="mt-8 text-lg leading-8 text-gray-300">
                  {clickedSol.description}
                </p>
              </div>
              <img
                src={clickedSol.img}
                alt="Product screenshot"
                className={`relative -z-20 min-h-full min-w-full max-w-xl rounded-xl shadow-xl ring-1 ring-white/10 lg:row-span-4 lg:h-[37.9rem] ${clickedSol.imgStyle} lg:max-w-none`}
              />
              <div className="max-w-xl lg:row-start-3 lg:mt-7 lg:max-w-md lg:border-t lg:border-white/10 lg:pt-7">
                <dl className="max-w-xl space-y-8 text-base leading-7 text-gray-300 lg:max-w-none">
                  {clickedSol.features.map((feature) => (
                    <div key={feature.name} className="relative">
                      <dt className="ml-9 inline-block font-semibold text-white">
                        <feature.icon className="absolute left-1 top-1 h-5 w-5 text-blue-500" aria-hidden="true" />
                        {feature.name}
                      </dt>{' '}
                      <dd className="inline">{feature.description}</dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
            <div
              className="pointer-events-none absolute left-12 top-1/2 -z-30 -translate-y-1/2 transform-gpu blur-3xl lg:bottom-[-12rem] lg:top-auto lg:translate-y-0 lg:transform-gpu"
              aria-hidden="true"
            >
              <div
                className="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-25"
                style={{
                  clipPath:
                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                }}
              />
            </div>
          </div>
          </div>


        </div>
        </div>
        </div>



        {/* SERVICIOS */}
        <div className='relative isolate mt-40 sm:mt-52 h-auto'>

          <div className='px-6 lg:px-8'>
          <div className="mx-auto max-w-4xl text-center">
                <h2 className="text-base font-semibold leading-7 text-blue-600">Servicios</h2>
                <p className="mt-2 text-4xl leading-10 font-bold tracking-tight text-gray-900 sm:leading-[4rem] sm:text-5xl">
                 Adapta, digitaliza y optimiza tu negocio
                </p>
              </div>
          <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
            Determina los servicios ideales para elevar a tu empresa en función a lo que necesitas.
          </p>
          </div>

          {/*
              <div className='text-sm text-cyan-400 underline underline-offset-4
              sm:text-base
              lg:text-lg'>
              Más información sobre Servicios
              </div> */}


          <div className='w-4/5 ml-auto mr-auto mt-16 sm:mt-20
          sm:grid sm:grid-cols-11 sm:w-full sm:mx-0'>
            <div className='h-min grid grid-cols-1 text-zinc-700 text-lg
            sm:col-span-6 sm:mr-8 sm:ml-8 
            md:mr-12 md:ml-12
            lg:col-start-2 lg:col-span-4 lg:ml-0 lg:mr-14
            2xl:ml-28 2xl:mr-20'>

              <ServiceTabLanding linkRef={'control'} clicked={clicked.control} onClick={() => ChangeTab("control")}  
              bgColor={serviceBGRes} img={serviceImg} title={"Optimización  de Activos Fijos"} text={"Cuidamos de tus bienes con un completo servicio de seguimiento, registro y gestión. Garantizamos la integridad de tu información financiera y maximizamos la eficiencia de tus recursos."}/>

              <ServiceTabLanding linkRef={'audit'} clicked={clicked.audit} onClick={() => ChangeTab("audit")}  
              bgColor={serviceBGRes} img={serviceImg} title={"Auditoría de inventarios"} text={"Realizamos una minuciosa revisión y verificación sistemática de los registros de tus productos. Nuestro objetivo es proporcionar información precisa y confiable, previniendo posibles errores o fraudes."}/>

              <ServiceTabLanding linkRef={'cred'} clicked={clicked.credencial} onClick={() => ChangeTab("credencial")}  
              bgColor={serviceBGRes} img={serviceImg} title={"Credencialización"} text={"Generamos tarjetas de identificación para una verificación visual de los miembros de tu empresa, así mismo proporcionando mecanismos de seguridad para el control de accesos a las instalaciones."}/>

              <ServiceTabLanding linkRef={'poliza'} clicked={clicked.poliza} onClick={() => ChangeTab("poliza")}  
              bgColor={serviceBGRes} img={serviceImg} title={"Garantías y Coberturas de Servicio"} text={"Proporcionamos seguros y garantías integrales para todos nuestros productos, cubriendo cualquier eventualidad, accidentes o funcionamiento defectuoso."}/>

              <ServiceTabLanding linkRef={'software'} clicked={clicked.software} onClick={() => ChangeTab("software")}  
              bgColor={serviceBGRes} img={serviceImg} title={"Desarrollo de Software"} text={"Diseñamos aplicaciones adaptado a tus requerimientos, proporcionando soluciones digitales para abordar tus áreas de oportunidad."}/>

            </div>

            <div className={`bg-gradient-to-r ${serviceBG} h-full hidden
            sm:grid sm:grid-cols-1 sm:col-span-5 sm:rounded-l-3xl
            lg:col-span-6`}>
              <div className=" bg-cover ml-0 my-20
              sm:rounded-l-2xl
              lg:ml-28 lg:my-20"
              style={{backgroundImage : `url(${serviceImg})`}}
              ></div>
            </div>
          </div>

        </div>

      
        {/* eCOMMERCE */}
        {/* <div className='relative w-full h-auto bg-sky-400'>
          <div className='w-4/5 ml-auto mr-auto py-20
          md:py-32 md:grid md:grid-cols-2 md:w-full md:ml-0 md:mr-0'>

            <div className='pb-48
            md:pb-0 md:grid md:grid-cols-6 md:content-center
            xl:grid xl:grid-cols-3'>
              <div className='
              md:col-start-2 md:col-span-4 
              xl:col-start-2 xl:col-span-2'>
                <div className='text-white text-5xl font-bold mb-8
                lg:text-6xl
                2xl:text-7xl'>
                eCommerce
                </div>
                <div className='text-white text-base leading-7 mb-4
                sm:w-5/6
                lg:text-lg
                2xl:text-xl'>
                    ¿Interesado en nuestros equipos de radio frecuencia? Entra a nuestra página de eCommerce y echa un vistazo a todo lo que tenemos para tí. (editado) 
                </div>
                <div className='text-sm underline underline-offset-4
                lg:text-base
                2xl:text-lg'>
                 Ir a la página de eCommerce
                </div>
              </div>
            </div>

            <div className='absolute bottom-20 left-0 w-full
            md:relative md:grid md:grid-cols-4 md:bottom-0 md:content-center md:mt-0'>
              <div className='sm:col-span-4
              xl:col-span-3'>
                <div className=''>
                  <Carousel
                    partialVisible
                    additionalTransfrom={0}
                    arrows
                    autoPlay
                    autoPlaySpeed={1800}
                    centerMode={false}
                    className="py-2"
                    containerClass="container-with-dots"
                    dotListClass=""
                    draggable
                    focusOnSelect={false}
                    infinite
                    itemClass=""
                    keyBoardControl
                    minimumTouchDrag={80}
                    pauseOnHover
                    renderArrowsWhenDisabled={false}
                    renderButtonGroupOutside={false}
                    renderDotsOutside={false}
                    responsive={{
                      largeDesktop: {
                        breakpoint: {
                          max: 3000,
                          min: 1536
                        },
                        items: 3,
                        partialVisibilityGutter: 30
                      },
                      desktop: {
                        breakpoint: {
                          max: 1830,
                          min: 768
                        },
                        items: 2,
                        partialVisibilityGutter: 30
                      },
                      tablet: {
                        breakpoint: {
                          max: 766,
                          min: 640
                        },
                        items: 3,
                        partialVisibilityGutter: 30
                      },
                      mobile: {
                        breakpoint: {
                          max: 640,
                          min: 0
                        },
                        items: 2,
                        partialVisibilityGutter: 30
                      }
                    }}
                    rewind={false}
                    rewindWithAnimation={false}
                    rtl={false}
                    shouldResetAutoplay
                    showDots={false}
                    sliderClass=""
                    slidesToSlide={1}
                    swipeable
                  >
                    <ProductPanel type={1} productData={productData}/>
                    <ProductPanel type={1} productData={productData}/>
                    <ProductPanel type={1} productData={productData}/>
                    <ProductPanel type={1} productData={productData}/>
                    <ProductPanel type={1} productData={productData}/>
                    <ProductPanel type={1} productData={productData}/>
                  </Carousel>
                  
                </div>
              </div>
            </div>

          </div>
        </div> */}


    <div ref={loadRef} className=''/>

    {/* CASOS DE ÉXITO */}
    <div className="bg-white py-24 sm:pb-32 sm:pt-56">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Casos de Éxito</h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            Conoce a los negocios beneficiados por nuestros servicios.
          </p>
        </div>
        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {storyData.filter((post, i)=> {
                if(i > 2){
                    return false
                }
                return true
            }).map((post) => (
            <Link to={`/story/${post.value}`}>
            <article key={post.id} className="flex flex-col items-start justify-between">
              <div className="relative w-full">
                <img
                  src={post.imageUrl}
                  alt=""
                  className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                />
                <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
              </div>
              <div className="max-w-xl">
                <div className="mt-8 flex items-center gap-x-4 text-xs">
                  <time dateTime={post.datetime} className="text-gray-500">
                    {post.date}
                  </time>
                  {post.category.map((category) => (
                      <p
                      className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100"
                      >
                        {category}
                      </p>
                  ))}
                </div>
                <div className="group relative">
                  <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                    <p>
                      <span className="absolute inset-0" />
                      {post.title}
                    </p>
                  </h3>
                  <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">{post.description}</p>
                </div>
                {/* <div className="relative mt-8 flex items-center gap-x-4">
                  <img src={post.author.imageUrl} alt="" className="h-10 w-10 rounded-full bg-gray-100" />
                  <div className="text-sm leading-6">
                    <p className="font-semibold text-gray-900">
                      <p>
                        <span className="absolute inset-0" />
                        {post.author.name}
                      </p>
                    </p>
                    <p className="text-gray-600">{post.author.role}</p>
                  </div>
                </div> */}
              </div>
            </article>
            </Link>
          ))}
        </div>
      </div>
    </div>



        {/* NAV SECCIONES */}
        {/* <div className='w-full h-auto bg-white'>
          <div className='grid grid-cols-9 mb-12 mt-20'>
            <div className='col-start-2 col-span-8 text-zinc-700 font-bold text-2xl
            md:text-3xl
            lg:text-4xl'>
              Comienza a trabajar con nosotros:
            </div>
          </div>
          <div className='grid grid-rows-3 h-[500px] bg-black
          md:grid md:grid-cols-3 md:grid-rows-1
          lg:h-[650px]'>

            <Link to={"/solutions"}>
            <BtnSectionNav image={FotoSolu} title={'Soluciones'} text={'Las respuestas que estabas buscando'} width={'[&_#under]:max-w-[355px]'}/>
            </Link>

            <Link to={"/services"}>
            <BtnSectionNav image={FotoServ} title={'Servicios'} text={'Conoce los servicios que tenemos para ti'} width={'[&_#under]:max-w-[383px]'}/>
            </Link>

            <BtnSectionNav image={FotoEcom} title={'eCommerce'} text={'Echa un vistazo a nuestros productos'} width={'[&_#under]:max-w-[347px]'}/>


          </div>
        </div> */}
    </div>
  )
}
