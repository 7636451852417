import { CheckCircleIcon } from '@heroicons/react/24/solid'
import controlImg from '../Media/audit.jpg'
import Foto_2 from '../Media/servicios2.jpg'
import Foto_3 from '../Media/servicios3.jpg'
import softwareImg from '../Media/software.jpg'

export const data = {
    control: {
        info:{
            name: 'Optimización  de activos fijos',
            subtitle:'Mantén el control, asegura el éxito',
            description:['Comprometidos con tus inversiones, ofrecemos un servicio avanzado que combina seguimiento preciso, registro detallado y gestión estratégica de activos fijos.', <div>Rastreamos en tiempo real, garantizando que conozcas la ubicación y estado de cada activo a lo largo de su vida útil. Mantenemos registros detallados, cumplimos regulaciones contables y fiscales, y optimizamos activamente el uso de tus recursos. Nuestra atención a registros precisos fortalece la confianza en la información financiera de tu empresa y protege tus activos. ¿Listo para llevar el rendimiento de tus activos fijos al siguiente nivel?</div>],
            img: controlImg
            // img: 'https://images.prismic.io/simpliroute/c6eba6c5-95d6-4c2b-a21f-0f6b3ad9ca38_rastreo+de+paqueteria.jpg?auto=compress,format'
        },
        bullet: [
            { 
                icon: CheckCircleIcon, name:'Administración de Activos', description: 'Monitoreamos todos tus bienes muebles e inmuebles.'
            },{
                icon: CheckCircleIcon, name:'Trazabilidad', description: 'Rastreamos todos los procesos y vida útil de cada bien.'
            },{
                icon: CheckCircleIcon, name:'Tomas Físicas Frecuentes', description: 'Nos permite prevenir y corregir a tiempo.'
            },{
                icon: CheckCircleIcon, name:'Agilización de Procesos', description: 'En RH, por ejemplo, al dar entrada o salida al personal.'
            },{
                icon: CheckCircleIcon, name:'Promover el uso adecuado', description: 'De todos tus bienes, y cómo manejarlos.'
            },{
                icon: CheckCircleIcon, name:'Inhibir el robo', description: 'O extracción de los bienes de las áreas asignadas.'
            }
        ]
    }, 
    audit: {
        info: {
            name: 'Auditoría de inventarios',
            subtitle: 'La Clave de la Eficiencia',
            description:["Revisamos y verificamos de manera sistemática los registros, procesos y procedimientos relacionados con el inventario de tu empresa.", <div>Nos aseguramos de que los datos contables y físicos del inventario coincidan y que el inventario esté debidamente valuado, registrado y controlado de acuerdo con las prácticas contables y las regulaciones aplicables.</div>],
            img: 'https://d1ih8jugeo2m5m.cloudfront.net/2022/11/como-rastrear-un-paquete-1200x685.jpg'
        },
        bullet:[
            {
                icon: CheckCircleIcon, name:'Conteos Físicos', description: 'Para compararlos contra los inventarios lógicos.'
            },{
                icon: CheckCircleIcon, name:'Validación de existencias', description: 'Por medio de ubicación.'
            },{
                icon: CheckCircleIcon, name:'Disminución de errores', description: 'Reducimos los errores al mínimo para una gestión sin contratiempos.'
            },{
                icon: CheckCircleIcon, name:'Conteos Cíclicos', description: 'Reduciendo los tiempos de cada toma.'
            }
        ]

    },
    credencial: {
        info: {
            name: 'Credencialización',
            subtitle: 'Identificación Efectiva', 
            description:'Fusionamos seguridad y carácter al proporcionar tarjetas de identificación personalizadas con mecanismos innovadores. Estas tarjetas no solo sirven para verificación visual, sino que también permiten un control preciso de accesos, simplificando la gestión de áreas específicas en tus instalaciones. Descubre una identificación más inteligente y segura, donde cada tarjeta cuenta una historia de autenticidad y control.',
            img: 'https://www.specialistid.com/cdn/shop/articles/Screenshot_2021-04-16_111756.png?v=1618586484'
        },
        bullet:[
            {
                icon: CheckCircleIcon, name:'Identidad Visual', description: 'Para los miembros de la empresa.'
            },{
                icon: CheckCircleIcon, name:'Mecanismos de seguridad', description: 'Monitoreo de los accesos a los inmuebles de la empresa.'
            },{
                icon: CheckCircleIcon, name:'Sentido de Pertenencia', description: 'Para los empleados.'
            },{
                icon: CheckCircleIcon, name:'Identificación', description: 'Permitiendo a los empleados presentarse como miembros.'
            },{
                icon: CheckCircleIcon, name:'Información fija y variable', description: 'Agregada a las credenciales con calidad fotográfica.'
            },{
                icon: CheckCircleIcon, name:'Reconocimiento de participantes', description: 'Para eventos específicos.'
            }
        ]

    },
    poliza: {
        info: {
            name: 'Garantías y Coberturas de Servicio',
            subtitle: 'Tu Tranquilidad, Nuestra Prioridad',
            description: ['Nos comprometemos a brindarte seguridad y confianza en cada experiencia. Ofrecemos seguros y garantías integrales para todos nuestros productos, abarcando cualquier imprevisto causado por accidentes o mal funcionamiento.', <div>Tu tranquilidad es nuestra principal prioridad; nos esforzamos por respaldar la calidad de nuestros productos y garantizar tu completa satisfacción. Puedes confiar en nosotros para superar cualquier eventualidad, manteniendo tu bienestar como nuestro enfoque central.</div>],
            img: 'https://segurosatlantida.com/wp-content/uploads/2018/05/renovar.jpg'
        },
        bullet:[
            {
                icon: CheckCircleIcon, name:'Conteos Físicos', description: 'Para compararlos contra los inventarios lógicos.'
            },{
                icon: CheckCircleIcon, name:'Validación de existencias', description: 'Por medio de ubicación.'
            },{
                icon: CheckCircleIcon, name:'Disminución de errores', description: 'Manuales o sistemáticos.'
            },{
                icon: CheckCircleIcon, name:'Conteos Cíclicos', description: 'Reduciendo los tiempos de cada toma.'
            }
        ]

    },
    software: {
        info:{   
            name: 'Desarrollo de Software',
            subtitle: 'Soluciones Adaptadas para Tu Éxito',
            description: 'Creamos aplicaciones personalizadas que se adaptan a tus necesidades empresariales específicas. Nuestro enfoque único y flexible garantiza soluciones digitales estratégicas, respaldadas por nuestro compromiso con la excelencia en el desarrollo de software para impulsar el éxito continuo de tu empresa.',
            img: softwareImg
        },
        bullet:[
            {
                icon: CheckCircleIcon, name:'Conteos Físicos', description: 'Para compararlos contra los inventarios lógicos.'
            },{
                icon: CheckCircleIcon, name:'Validación de existencias', description: 'Por medio de ubicación.'
            },{
                icon: CheckCircleIcon, name:'Disminución de errores', description: 'Manuales o sistemáticos.'
            },{
                icon: CheckCircleIcon, name:'Conteos Cíclicos', description: 'Reduciendo los tiempos de cada toma.'
            }
        ]
    },
}